<template>
  <div>
    <Header title="卖品配置" @back="onBack"></Header>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="可用影院" style="margin-right: 30px;">
            <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item name="mainTitle" label="卖品名称" style="margin-right: 30px;">
            <a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称"></a-input>
          </a-form-item>
          <a-form-item v-if="id" name="isDisabled" label="状态" style="margin-right: 30px;">
            <a-select v-model:value="formState.isDisabled" style="width: 180px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">已启用</a-select-option>
              <a-select-option :value="0">已禁用</a-select-option>
            </a-select>
          </a-form-item>

        </a-row>
        <a-row>
          <a-col :span="18">
            <a-button type="primary" :disabled="selectedRowKeys.length === 0 " style="margin-right: 10px;" @click="onSnackDel" >批量移除</a-button>

            <span style="display: inline-block">
							<snackModal v-model:value="snackIds" :selectItem="snackList" :cinemaIds="snackCinemaIds" @change="onSnackChange" :organizationId="organizationId" snackType="snackSet" :batchId="id" @onAddSnack="onAddSnack"></snackModal>
						</span>

          </a-col>

          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :columns="snackColumns"
                 :dataSource="snackList"
                 :pagination="pagination"
                 :scroll="{ x: 1300 }"
                 :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, onChange: onChange }"
                 bordered size="middle"
        >
          <template #bodyCell="{ column, record ,index}">
            <template v-if="column.key === 'img'">
              <a-image style="width: 40px;" :src="record.imgUrl"></a-image>
            </template>

            <template v-if="column.key === 'card'">
              <div v-if="record.isCard">
                <a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
                <div>卡次数：{{ record.cardBalanceTimes }}</div>
                <div>卡等级：{{ record.cardTitle || '' }}</div>
              </div>
              <div v-else>--</div>
            </template>
            <template v-if="column.key === 'action'">
              <a-button type="link" @click="onDelete(record, index)">移除</a-button>
            </template>
          </template>
        </a-table>

      </div>

      <div style="text-align: center; margin-top: 20px">
        <a-button @click="onBack">返回</a-button>
<!--        <a-button @click="onAddSnack" style="margin-left: 20px" type="primary">提交</a-button>-->
      </div>
    </a-spin>

  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import Header from '@/components/header/header.vue';
import exportComponent from '@/components/exportReport/exportReport.vue';
import {
  getExchangeList,
  updateIssue,
  bindVoucher,
  batchBindVoucher,
  updateIsDisabled,
  delCoupon,
  couponBatchDetail,
  asyncBatchEnable,
  asyncBatchDisable,
  updateCinemaGoods,
  couponPolicyList,
  couponDelete,
  batchSave,
  batchDelete
} from "@/service/modules/coupon.js";
import Snack from "@/views/report/normal/snack.vue";
import snackModal from "@/components/snackSelect/index.vue";
import { getCinemaList } from "@/service/modules/cinema";
export default {
  components: {
    snackModal,
    Snack,
    Icon,
    Header,
    exportComponent
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    snackCinemaIds:{
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      loading: false,
      formState: {
        isDisabled: '',
        cinemaId:''
      },
      searchData: {},
      detail: {},
      seeVisible: false,
      visible: false,
      setVisible: false,
      selectedRowKeys: [],
      selectedListData: [],
      selectedColumns: [{
        title: '所属影院',
        dataIndex: 'cinemaName'
      }, {
        title: '批次号',
        dataIndex: 'batchNo'
      }, {
        title: '卖品券号',
        dataIndex: 'barcode'
      }, {
        title: '有效期',
        key: 'time'
      }, {
        title: '绑定状态',
        key: 'status'
      }],
      bindItem: {},
      memberModelRef: {
        member: ''
      },
      list: [],
      columns: [{
        title: '所属影院',
        dataIndex: 'cinemaName'
      }, {
        title: '批次号',
        dataIndex: 'batchNo'
      }, {
        title: '卖品券号',
        key: 'barcode',
        dataIndex: 'barcode'
      }, {
        title: '有效期',
        key: 'time'
      },{
        title: '绑定状态',
        key: 'status',
        width: 130
      }, {
        title: '发放状态',
        key: 'isIssue',
        width: 130
      }, {
        title: '是否启用',
        key: 'isDisabled',
        width: 130
      }, {
        title: '是否商品卖品发放',
        key: 'isGrant'
      }, {
        title: '创建时间',
        key: 'createTime',
        width: 200
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 160
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      snackList: [],
      snackColumns: [{
        title: '卖品所属影院',
        dataIndex: 'cinemaName'
      },
        {
        title: '卖品名称',
        dataIndex: 'mainTitle'
      }, {
        title: '卖品图片',
        key: 'img'
      }, {
        title: '卡信息',
        key: 'card'
      }, {
          title: '操作',
          key: 'action'
        }],
      taskVisible: false,
      taskTitle: '',
      taskType: '',
      cinemaIds:[],
      cinemaId:'',
      snackIds: [],
      selectSnackIds:[],
      organizationId:'',
      mycinemaIds:[],
      cinemaAllList:[],
    }
  },
  created() {
    this.onSearch();
    this.getAllCinemaList()

    // if (this.id) {
    //   this.getDetail();
    // }
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.selectedListData = [];
      this.selectedRowKeys = [];
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
      this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    async getAllCinemaList() {
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
      })
      if (ret.code === 200) {
        console.log(this.cinemaAllList,this.snackCinemaIds)
        // this.cinemaList = ret.data.list;
        this.cinemaAllList =ret.data.list.filter(item => this.snackCinemaIds.includes(String(item.id)));


      }
    },

    getData() {
      this.getDetail();
    },
    async getDetail() {
      this.loading = true
      try {
        let ret = await couponPolicyList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          batchId: this.id,
          ...this.searchData
        });
        if (ret.code === 200) {
          if (ret.data.list && ret.data.list.length) {
            const snackIds = [];
            const snackGoodId = []
            ret.data.list.forEach(item=>{
              if (item) this.snackList.push(item);
              snackIds.push(item.id)
              snackGoodId.push(item.goodsId)
            })
            this.snackIds = snackIds;
            this.selectSnackIds = snackGoodId
          }
          this.snackList = ret.data.list
          this.pagination.total = ret.data.totalCount;
          this.loading = false
        }
      } catch(e) {
        this.loading = false

      }

    },
    filterArray(obj, arr){
      const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
      return arr.filter(item => values.includes(item.cinemaId));
    },
    onChange(key) {

    },
    async onAddSnack() {

      if (this.snackList.length != 0){
        let cinemaGoodsList = []
        this.snackList.forEach((item)=>{
          if (!item.cinemaMiddleId){
            let goodsItem ={}
            goodsItem.cinemaId = item.cinemaId
            goodsItem.batchId = this.id
            goodsItem.goodsId = item.id
            goodsItem.type = 1
            cinemaGoodsList.push(goodsItem)
          }
        })
        this.loading = true;
        try {

          let ret = await batchSave(cinemaGoodsList);
          this.loading = false;
          if (ret.code === 200) {
            this.$message.success('提交成功')
            this.getDetail();
          }
        } catch(e) {
          this.loading = false;

        }


      }
    },
    onSnackChange(list) {
      this.snackList = JSON.parse(JSON.stringify(list));

    },
    onSee(item) {
      this.seeVisible = true;
      this.detail = item;
    },
    getCheckboxProps(record) {
      return { disabled: record.status === 1 }
    },
    onSelectChange(record, selected) {
      if (selected) {
        this.selectedListData.push(JSON.parse(JSON.stringify(record)));
        this.selectedRowKeys.push(record.id);
      } else {
        this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
        this.selectedListData = this.selectedListData.filter(item => {
          return item.id !== record.id;
        });
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.snackList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            this.selectedListData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.snackList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    onBatchDisabled() {
      this.$confirm({
        title: "提示",
        content: '确定批量禁用选择的卖品券吗？',
        onOk: async ()=> {
          this.loading = true;
          try {
            let ret = await asyncBatchDisable({
              ids: this.selectedRowKeys.join(','),
              type: this.taskType,
              title: this.taskTitle
            })
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('任务创建成功，请在任务中心-任务列表查看完成情况');
              this.selectedRowKeys = [];
              this.selectedListData = [];
              this.taskVisible = false;
              this.getData();
            }
          } catch(e) {
            console.log(e)
            this.loading = false;
          }
        }
      })
    },
    onDelete(item,index) {
      // if (item.cinemaMiddleId){
        this.$confirm({
          title: '提示',
          content: '确定删除该卖品券吗？',
          onOk: async ()=> {
            this.loading = true;
            try {
              let ret = await couponDelete({
                id:item.cinemaMiddleId
              })
              this.loading = false;
              if (ret.code === 200) {
                this.$message.success('删除成功');
                // let i = this.snackIds.indexOf(item.id);
                // if (i !== -1) {
                //   this.snackIds.splice(i, 1);
                // }
                // this.snackList.splice(index, 1);
                this.getDetail()
              }
            } catch(e) {
              this.loading = false;
              console.log(e)
            }
          }
        })
    },
    async batchDelete(ids) {
      // if (ids.length != 0){
        this.$confirm({
          title: '提示',
          content: '确定删除所选卖品券吗？',
          onOk: async ()=> {
            this.loading = true;
            try {
              let ret = await batchDelete({
                ids:ids.toString()
              })
              this.loading = false;
              if (ret.code === 200) {
                this.$message.success('删除成功');
                this.snackList = this.snackList.filter(
                  item => !this.selectedRowKeys.includes(item.id)
                );
                this.selectedRowKeys = []; // 清空已选项

                this.getDetail()
              }
            } catch(e) {
              this.loading = false;
              console.log(e)
            }
          }
        })
    },


    onSnackDel(){
      let ids = []
      this.selectedListData.forEach((item)=>{
        let i = this.snackIds.indexOf(item.id);
        if (i !== -1) {
          this.snackIds.splice(i, 1);
        }
        if (item.cinemaMiddleId){
          ids.push(item.cinemaMiddleId)
        }
      })
      this.batchDelete(ids)
      },


    onShowTaskModal(type) {
      this.taskType = type;
      this.taskTitle = '';
      this.taskVisible = true;
    },
  },

}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
