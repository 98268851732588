<template>
	<span>
		<span @click="onShowModal">
			<slot>
				<a-button type="primary">配置过滤用户</a-button>
			</slot>
		</span>
		<a-modal v-model:visible="visible" title="配置过滤用户" width="810px" @cancel="onCancel" @ok="onOK">
			<a-spin :spinning="loading">
				<a-form ref="form" name="form" :model="formState" @finish="onSearch">
					<a-row>
						<a-form-item class="a-form-item" label="账号" name="username">
						  <a-input placeholder="请输入账号" v-model:value="formState.username" />
						</a-form-item>
						
						<a-form-item class="a-form-item" label="用户名" name="nickname">
						  <a-input placeholder="请输入用户名" v-model:value="formState.nickname" />
						</a-form-item>
						
						<a-form-item class="a-form-item" label="手机号" name="phone">
						  <a-input placeholder="请输入手机号" v-model:value="formState.phone" />
						</a-form-item>
					</a-row>
					
					<a-row>
						<a-col :span="18" style="padding-top: 10px;">
							<span>已选择{{ selectedRowKeys.length }} 位</span>
							<a-button type="link" size="small" @click="onCleck">清空选择</a-button>
						</a-col>
						<a-col :span="6" style="text-align: right;">
							<a-button type="primary" html-type="submit">搜索</a-button>
							<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>
				
				<a-table rowKey="id" 
					style="margin-top: 10px;" 
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" 
					:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
				>
				</a-table>
			</a-spin>
		</a-modal>
	</span>
</template>

<script>
	import { userList } from '@/service/modules/system.js';
	import { setAdminUserCoupon, getAdminUserCoupon } from '@/service/modules/coupon.js';
	export default {
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: {
				type: String,
				default: 'checkbox'
			},
			pageType: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
				  title: "账号",
				  dataIndex: "username",
				},
				{
				  title: "用户名",
				  dataIndex: "nickname",
				},
				{
				  title: "手机号",
				  dataIndex: "phone",
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			onShowModal() {
				this.visible = true;
				this.$nextTick(() => {
					this.reset();
				})
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState))
				this.loading = true;
				Promise.all([this.getData(), this.getAdminUserCoupon()]).then(() => {
					this.loading = false;
				})
				// this.getData();
				// this.getAdminUserCoupon();
			},
			async getData() {
				try {
					let ret = await userList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAdminUserCoupon() {
				let ret = await getAdminUserCoupon({
					type: this.pageType
				});
				if (ret.code === 200) {
					if (!ret.data || JSON.stringify(ret.data) === '{}') {
						this.selectedRowKeys = [];
					} else {
						this.selectedRowKeys = ret.data.split(',').map(id => Number(id));
					}
				}
			},
			async setAdminUserCoupon() {
				this.loading = true;
				let ret = await setAdminUserCoupon({
					type: this.pageType,
					ids: this.selectedRowKeys.join(',')
				});
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('设置成功');
					this.visible = false;
					this.selectedRowKeys = [];
					this.$emit('change');
				}
			},
			onCleck() {
				// this.selectedListData = [];
				this.selectedRowKeys = [];
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						// this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						// this.selectedListData = this.selectedListData.filter(item => {
						// 	return item.id !== record.id;
						// });
					}
				} else {
					if (selected) {
						// this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						// this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							// this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							// this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onCancel() {
			    this.visible = false;
			},
			onOK() {
				// this.$emit('update:value', this.selectedRowKeys);
				// this.$emit('change', this.selectedListData);
				this.setAdminUserCoupon();
			}
		}
	}
</script>

<style scoped>
	.a-form-item {
		margin-right: 20px;
	}
</style>
